import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "averageEstimate",
    "highEstimate",
    "lowEstimate",
    "resultsAverage",
    "resultsHigh",
    "resultsLow",
  ]

  averageEstimateTarget: HTMLInputElement;
  hasResultsHighTarget: boolean;
  hasResultsLowTarget: boolean;
  highEstimateTarget: HTMLInputElement;
  lowEstimateTarget: HTMLInputElement;
  resultsAverageTarget: HTMLSpanElement;
  resultsHighTarget: HTMLSpanElement;
  resultsLowTarget: HTMLSpanElement;


  showResults() {
    this.updateResults();
    document.getElementById("resultsHiddenContainer").classList.add("d-none");
    document.getElementById("resultsVisibleContainer").classList.remove("d-none");
  }

  hideResults() {
    document.getElementById("resultsHiddenContainer").classList.remove("d-none");
    document.getElementById("resultsVisibleContainer").classList.add("d-none");
  }

  updateResults() {
    this.resultsAverageTarget.innerText = this.averageEstimateTarget.innerText;

    if (this.hasResultsHighTarget) {
      this.resultsHighTarget.innerText = this.highEstimateTarget.innerText;
    }

    if (this.hasResultsLowTarget) {
      this.resultsLowTarget.innerText = this.lowEstimateTarget.innerText;
    }
  }
}
