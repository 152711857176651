import { Controller } from "stimulus";
import { Fetcher } from "../fetcher";
import { notification } from "../components/notification";
import { Modal } from "bootstrap";

export default class extends Controller {
  static values = {
    jobId: Number,
    leadPrice: Number,
  };

  static targets = [
    "form",
    "modal",
    "suggestedLead",
    "suggestedQuote",
    "suggestedLeadError",
    "suggestedQuoteError",
    "modalLink",
  ];

  static modal;

  connect() {
    this.fetcher = new Fetcher({ parseErrorResponse: true });
    this.modal = new Modal(this.modalTarget);
  }

  closeModal(event) {
    event.preventDefault();
    this.modal.hide();
  }

  openModal(event) {
    event.preventDefault();
    this.modal.show();
  }

  create(event) {
    event.preventDefault();

    const url = this.formTarget.action;

    this.fetcher
      .request(url, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user_feedback_lead_price: {
            job_id: this.jobIdValue,
            lead_price: this.leadPriceValue,
            suggested_lead: this.suggestedLeadTarget.value,
            suggested_quote: this.suggestedQuoteTarget.value,
          },
        }),
      })
      .then((res) => {
        if (res.error) {
          const errors = res.error[0];

          if (errors.suggested_quote) {
            this.suggestedQuoteErrorTarget.textContent = errors.suggested_quote;
          } else {
            this.suggestedQuoteErrorTarget.textContent = "";
          }

          if (errors.suggested_lead) {
            this.suggestedLeadErrorTarget.textContent = errors.suggested_lead;
          } else {
            this.suggestedLeadErrorTarget.textContent = "";
          }
        } else {
          notification(res.message, "success");
          this.modal.hide();
          this.modalLinkTarget.remove();
        }
      });
  }
}
