import { Controller } from "stimulus";
import { German } from "flatpickr/dist/l10n/de";

export default class extends Controller {
  private pickerTarget: Element;

  private completionDateTarget: HTMLSelectElement;
  private completionPeriodTarget: HTMLSelectElement;

  static targets = ["picker", "completionDate", "completionPeriod"];

  private datePicker;

  connect(): void {
    // @ts-ignore
    import("flatpickr/dist/themes/material_blue.css");

    import(/* webpackChunkName: "flatpickr" */ "flatpickr").then((module) => {
      this.datePicker = module.default(this.pickerTarget, {
        locale: German,
        minDate: "today",
        disableMobile: true,
        dateFormat: "d-m-Y",
        onChange: function (selectedDates, dateStr) {
          this.completionDateTarget.value = dateStr;
        }.bind(this),
      });

      this.onCompletionChange(false);
    });
  }

  onCompletionChange(openPicker: boolean): void {
    if (this.completionPeriodOption.dataset.picker === "true") {
      this.completionDateTarget.classList.remove("hidden");
      this.pickerTarget.classList.remove("hidden");
      if (openPicker) {
        this.datePicker.open();
      }
    } else {
      this.completionDateTarget.value = "";
      this.completionDateTarget.classList.add("hidden");
      this.pickerTarget.classList.add("hidden");
    }
  }

  openPicker(event): void {
    event.preventDefault();
  }

  get completionPeriodOption(): HTMLOptionElement {
    return this.completionPeriodTarget.options[
      this.completionPeriodTarget.selectedIndex
    ];
  }
}
