import { Controller } from "stimulus";

export default class extends Controller {
  private hasGoogleAnalyticsEventAttachedValue: boolean;

  static values = {
    hasGoogleAnalyticsEventAttached: Boolean,
  };

  actionConfirmed = false;

  onClick(event: Event): void {
    if (this.actionConfirmed) return;

    event.preventDefault();
    event.stopPropagation();

    import(
      /* webpackChunkName: "sweetalert2" */ "sweetalert2/src/sweetalert2"
    ).then((module) => {
      module.default
        .fire({
          text: this.data.get("text"),
          type:
            this.data.get("type") !== undefined
              ? this.data.get("type")
              : "question",
          showCancelButton: true,
          confirmButtonText: this.data.get("confirm"),
          cancelButtonText: this.data.get("cancel"),
          customClass: {
            confirmButton: "btn btn-primary w-45 me-3",
            cancelButton: "btn btn-light w-45",
            icon: "confirmation-icon",
          },
          buttonsStyling: false,
        })
        .then((result) => {
          if (result.value) {
            this.actionConfirmed = true;
            const element = this.element as HTMLFormElement;

            if (element.tagName === "FORM") {
              element.submit();
            } else {
              element.click();
            }
          }
        });
    });
  }
}
