import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "loadScope",
    "distanceScope",
    "finalCleaningContainer",
    "finalCleaningScope",
    "finalCleaningSwitch",
    "finalCleaningTemplate",
  ];

  static values = {
    data: Object,
  }

  dataValue: any;
  distanceScopeTarget: HTMLInputElement;
  finalCleaningContainerTarget: HTMLDivElement;
  finalCleaningScopeTarget: HTMLInputElement;
  finalCleaningSwitchTarget: HTMLInputElement;
  finalCleaningTemplateTarget: HTMLInputElement;
  loadScopeTargets: HTMLInputElement[];

  connect() {
    this.update();
  }

  update() {
    let loadScope = this.getLoadScope();
    let distanceScope = parseFloat(this.distanceScopeTarget.value);
    let finalCleaningScope = this.finalCleaningSwitchTarget.checked ? this.finalCleaningScopeTarget.value : false;

    let averageEstimate = this.calculate(this.dataValue.load[loadScope], distanceScope, finalCleaningScope);

    document.getElementById("average_estimate").innerText = averageEstimate;
  }

  switchFinalCleaning() {
    if (this.finalCleaningSwitchTarget.checked) {
      this.finalCleaningContainerTarget.innerHTML = this.finalCleaningTemplateTarget.innerHTML;
    } else {
      this.finalCleaningContainerTarget.innerHTML = "";
    }
  }

  calculate(loadCost, distance, finalCleaning) {
    let distanceCost = this.dataValue.distance.min_cost + (distance - this.dataValue.distance.slider[0]) * this.dataValue.distance.cost_per_km;
    let finalCleaningCost = finalCleaning == false ? 0 : this.dataValue.final_cleaning.cost[finalCleaning];
    return loadCost + distanceCost + finalCleaningCost;
  }

  getLoadScope(): string {
    return this.loadScopeTargets
      .find((option) => option.checked)
      .value
  }
}
