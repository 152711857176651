import iziToast from "izitoast";

export function notification(
  message: string,
  notificationType: string,
  timeout: number | boolean = 9000,
): void {
  const backgroundColors = {
    error: "#ffeae8",
    notice: "#f5f6f7",
    success: "#dcf6d6",
    warning: "#fffadf",
  };

  iziToast.show({
    class: "iziToast-custom",
    icon: `${notificationType}-notification-icon`,
    message: message,
    position: "topCenter",
    timeout: timeout,
    progressBar: false,
    // display mode /!\ SEEMS TO BE BROKEN
    displayMode: 2, // replace previous notification
    transitionIn: "flipInX",
    transitionOut: "flipOutX",
    backgroundColor: backgroundColors[`${notificationType}`],
    layout: 2, // medium layout
  });
}

export function serverErrorNotification(): void {
  notification("Serverfehler, bitte versuchen Sie es erneut.", "error");
}

export default { notification, serverErrorNotification };
