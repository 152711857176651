import { Controller } from "stimulus";
import { notification } from "../components/notification";
import { Modal } from "bootstrap";

export default class extends Controller {
  static targets = ["modal", "submit"];
  static modal;

  connect() {
    // there is some crazy level nesting with abuse_modal partial which also serves as a layout
    // modal is not always there (not logged in user)
    if (this.hasModalTarget) {
      this.modal = new Modal(this.modalTarget);
    }
  }

  showModal(event) {
    event.preventDefault();
    this.submitTarget.setAttribute("href", event.currentTarget.dataset.url);
    this.modal.show();
  }

  closeModal(event) {
    event.preventDefault();
    this.modal.hide;
  }

  onReportSuccess(event) {
    this.modal.hide();

    const json = event.detail[0];

    document.querySelector(`#rating-${json.id} .vote-box`).innerHTML =
      json.content;

    notification(json.message, "success");
  }
}
