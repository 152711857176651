import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["textarea"];
  static values = { maxRows: Number };

  private maxRowsValue: number;
  private textareaTarget: HTMLTextAreaElement;

  connect() {
    this.textareaTarget.setAttribute(
      "style",
      "height:" + this.textareaTarget.scrollHeight + "px;overflow-y:hidden;",
    );
  }

  resize() {
    const rows = this.textareaTarget.value.split("\n").length;
    if (this.maxRowsValue && rows >= this.maxRowsValue) {
      this.textareaTarget.style.overflowY = "auto";
      return;
    }

    this.textareaTarget.style.height = "auto";
    this.textareaTarget.style.height = this.textareaTarget.scrollHeight + "px";
  }
}
