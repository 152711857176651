import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["label"];

  labelTargets: HTMLLabelElement[];

  selectOption(event) {
    this.labelTargets.forEach((label) => {
      label.classList.remove("active");
    });

    event.target.classList.add("active");
  }
}
