import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["uneditableForm", "editableForm"];

  editableFormTarget: HTMLElement;
  uneditableFormTarget: HTMLElement;

  switchToEditForm(event) {
    event.preventDefault();
    this.uneditableFormTarget.classList.add("d-none");
    this.editableFormTarget.classList.remove("d-none");

    const firstVisibleInput = this.editableFormTarget.querySelector(
      "input:not([type='hidden'])",
    ) as HTMLElement;
    if (firstVisibleInput) {
      firstVisibleInput.focus();
    }
  }

  switchToDescriptionForm(event) {
    event.preventDefault();
    this.uneditableFormTarget.classList.remove("d-none");
    this.editableFormTarget.classList.add("d-none");
  }
}
