import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["slider", "value"];

  static values = {
    props: Object,
  };

  propsValue: { min: number, max: number, round: number, renderedMin: string, renderedMax: string };
  sliderTarget: HTMLInputElement;
  valueTarget: HTMLSpanElement;

  connect() {
    this.refresh();
  }

  refresh() {
    const value = this.getValue();
    const totalRange = (this.maxValue - this.minValue)
    const offsetPercentage = (this.minValue / totalRange) * 100
    const coveragePercentage = value / totalRange * 100 - offsetPercentage

    this.valueTarget.innerText = this.getRenderedValue();
    this.sliderTarget.style.background = `linear-gradient(to right, #3258A7FF 0%, #3258A7FF ${coveragePercentage}%, #e1e3e5 ${coveragePercentage}%)`
  }

  getValue() : number {
    return parseFloat(this.sliderTarget.value);
  }

  getRenderedValue() : string {
    let value;

    if (this.minValue == this.getValue()) {
      value = this.propsValue.renderedMin;
    }

    if (this.maxValue == this.getValue()) {
      value = this.propsValue.renderedMax;
    }

    return value || this.getValue().toFixed(this.roundValue).toString();
  }

  get minValue() : number {
    return this.propsValue.min;
  }

  get maxValue() : number {
    return this.propsValue.max;
  }

  get roundValue() : number {
    return this.propsValue.round || 0;
  }
}
